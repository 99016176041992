<i18n lang="json5">{
	ru: {
		all_airports: 'все аэропорты',
	},
	uz: {
		all_airports: 'barcha aeroportlar',
	}
}</i18n>

<template>
    <div class="relative" v-click-outside="onClickOutside">
        <input
			type="text"
			:value="title"
			class="relative focus:z-[1] w-full py-3 sm:py-4 pl-12 sm:pl-14 pr-14 sm:pr-16 bg-white text-black text-base sm:text-lg font-bold rounded-md border-0 focus:shadow-highlight placeholder:text-gray-400 touch-none"
			:class="[inputClass]"
			:placeholder="placeholder"
			ref="input"
			@input="typeName"
		/>
        <img
			src="/design/aircraft-up-ico2.svg"
			class="absolute z-[1] left-4 sm:left-5 lg:left-6 top-3 sm:top-5 w-input-ico pointer-events-none"
			:class="{'avia-ico-rotated': icoRotate}"
			alt
		/>
        <span class="absolute z-[1] right-4 sm:right-5 top-3 sm:top-5 sm:-mt-px text-my-blue font-bold pointer-events-none">
			{{ value }}
		</span>
        <div class="input-hint-box h-6 overflow-hidden pr-14 lg:pr-0">
            <div
				v-for="(destination, index) in destinations"
				class="inline-block"
			>
				<template v-if="index !== 0">, </template>
				<span
					class="cursor-pointer lg:hover:underline"
					:data-name="destination.name"
					:data-code="destination.code"
					@click="fromHint"
				>{{ destination.name }}</span>
			</div>
        </div>
        <div class="input-error-box bg-my-red" :class="[inputClassError]" v-if="error">
			{{ errorText }}
		</div>
        <transition name="fade">
            <div
				class="absolute left-0 top-full w-full sm:w-auto bg-white border border-t-0 shadow-hint-top text-base z-10 overflow-y-auto overflow-x-hidden overscroll-contain cursor-pointer rounded-md text-left"
				v-if="hintBox"
			>
                <div
					v-for="area in areas"
					:key="area.code"
					class="w-full sm:w-96 max-w-[calc(100vw-3rem)]"
					@click="selectAirport(area.code, area.name_intl[$i18n.locale] || area.name_intl['en'])"
				>
					<div class="group relative border-t pt-1 lg:hover:bg-my-blue lg:hover:text-white">
						<div class="font-bold pl-6 leading-tight">
							{{ area.name_intl[$i18n.locale] || area.name_intl['en'] }}
							<span class="font-normal text-xs text-gray-500 lg:group-hover:text-white">
								({{ $t('all_airports') }})
							</span>
						</div>
						<div class="text-xs text-gray-500 lg:group-hover:text-white pl-6 pb-2 leading-3">
							{{ area.country_intl[$i18n.locale] || area.country_intl['en'] }}
						</div>
						<div class="absolute right-6 top-3 pl-2 text-xs text-gray-500 lg:group-hover:text-white">
							{{ area.code }}
						</div>
					</div>
                    <div
						v-for="airport in area.airports"
						:key="airport.code"
						class="group relative border-t pt-1 pb-2 pl-12 pr-14 lg:hover:bg-my-blue lg:hover:text-white"
						@click.stop="selectAirport(airport.code, airport.name_intl[$i18n.locale] || airport.name_intl['en'])"
					>
                        <div class="font-bold leading-tight">
							{{ airport.name_intl[$i18n.locale] || airport.name_intl['en'] }}
						</div>
                        <div class="text-xs text-gray-500 lg:group-hover:text-white leading-3">
	                        ({{ airport.city_intl[$i18n.locale] || airport.city_intl['en'] }})
						</div>
                        <div class="absolute right-6 top-3 pl-2 text-xs text-gray-500 lg:group-hover:text-white">
							{{ airport.code }}
						</div>
                    </div>
                </div>
                <div
					v-for="(airport, index) in airports"
					:key="airport.code"
					class="group relative pt-1 pb-2 pl-6 pr-14 w-full sm:w-96 max-w-[calc(100vw-3rem)] lg:hover:bg-my-blue lg:hover:text-white"
					:class="{'border-t': index !== 0}"
					@click="selectAirport(airport.code, airport.name_intl[$i18n.locale] || airport.name_intl['en'])"
				>
                    <div class="font-bold leading-tight">
	                    {{ airport.name_intl[$i18n.locale] || airport.name_intl['en'] }}
						<span class="font-normal" v-if="airport.name !== airport.city">
							({{ airport.city_intl[$i18n.locale] || airport.city_intl['en'] }})
						</span>
					</div>
                    <div class="text-xs text-gray-500 lg:group-hover:text-white leading-3">
						{{ airport.country_intl[$i18n.locale] || airport.country_intl['en'] }}
					</div>
                    <div class="absolute right-6 top-3 pl-2 text-xs text-gray-500 lg:group-hover:text-white">
						{{ airport.code }}
					</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            value: String,
            title: String,
            placeholder: String,
            inputClass: String,
            icoRotate: Boolean,
			rtl: Boolean,
			autofocus: Boolean,
            error: Boolean,
            errorText: String,
            inputClassError: String,
            destinations: Array,
	        showAirportsOnly: Boolean,
        },

        data() {
            return {
                hintBox: false,
                areas: [],
                airports: [],
            }
        },

		mounted() {
			if (this.autofocus) {
				this.$refs.input.focus()
			}
		},

        methods: {
            /**
             * Use to handle the airport name input event.
             */
            typeName(event) {
				const phrase = event.target.value

                this.$emit('input', null);
                this.$emit('update:title', phrase);
                this.$emit('update:error', false);

                if (phrase.trim().length > 1) {
                    this.$store.dispatch('avia/getAirportHints', {
						phrase: phrase.trim(),
						limit: 8
					})
                        .then(response => {
							if (this.showAirportsOnly) {
								this.airports = [
									...(response.areas?.map(area => area.airports).flat() || []),
									...(response.airports || [])
								];
							} else {
								this.airports = response.airports;
								this.areas = response.areas;
							}

                            this.hintBox = true;

							if (window.innerWidth < 1024 && this.$refs.input) {
								window.scrollTo({
									top: window.scrollY + this.$refs.input.getBoundingClientRect().top,
									behavior: 'smooth'
								})
							}
                        })
                } else {
                    this.hintBox = false;
                    this.airports = [];
                }
            },

            /**
             * Use to handle a click on an airport from a tooltip.
             */
            selectAirport(code, name) {
                this.$emit('input', code);
                this.$emit('update:title', name);
                this.hintBox = false;
                this.$emit('update:error', false);

                setTimeout(() => {
                    this.airports = [];
                }, 1000);
            },

            /**
             * Use to select a destination from
             * the suggestions below the field.
             */
            fromHint(event) {
                this.$emit('input', event.target.dataset.code);
                this.$emit('update:title', event.target.dataset.name);
                this.$emit('update:error', false);
            },
			onClickOutside() {
				this.hintBox = false;
				setTimeout(() => {
					this.airports = [];
				}, 1000);
				if (!this.value) {
					this.$emit('update:title', null);
				}
			}
        },
    }
</script>

<style scoped>
.input-error-box {
	pointer-events: none;
}
@media (max-width: 639px) {
	.input-error-box {
		top: auto;
		bottom: 2rem;
	}
}
@media (min-width: 1280px) {
	.input-error-box {
		top: auto;
		bottom: 2.75rem;
	}
}
</style>
